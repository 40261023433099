import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'
// import { objectToUrlParam } from '@/libs/helper'

export default class PannelIbApis {
  getRegisterFieldData = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/register/data-form`);

  getRegisterData = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/register`)

  dashboard = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/dashboard`)

  register = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/register/store`, data)

  clients = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client${objectToUrlParam(data)}`)

  forexTradeHistory = async (id, data) => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client/${id}/history/trade/forex`, { params: data })

  clientDetail = async (id, data) => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client/${id}/detail${objectToUrlParam(data)}`)

  clientAccounts = async id => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client/${id}/accounts`)

  dashboard = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/dashboard`)

  metatraderAccountHistory = async (id, accid, data) => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client/${id}/account/history/${accid}`,
    { params: data })

  moneyTransfer = async (id, data) => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client/${id}/transfer`, data)

  transferHistory = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client/history/transfer`, { params: data })

  referralCode = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/profile/referral`)

  chartDepositWithdrawal = async (type, data, options) => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/report/chart/${type}`, { ...options, params: data })

  commissions = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/commissions`)

  demoAccounts = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/ib/client/demo/accounts${objectToUrlParam(data)}`)
}
